import Question from 'models/Question';
import React from 'react';
import AddImageButton from 'view/components/buttons/AddImageButton';

interface MultiPartReadAloudFrameProps {
  question: Question;
  onUpdateQuestion: (
    updatedQuestion: Question,
    submit?: boolean,
    image?: File,
  ) => void;
}

const MultiPartReadAloudFrame: React.FC<MultiPartReadAloudFrameProps> = ({
  question,
  onUpdateQuestion,
}) => {
  const handleUpdatePrompt = (text: string) => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.text = text;

    onUpdateQuestion(updatedQuestion, false);
  };

  const handleAddImage = (file?: File) => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.image_file = file;
    onUpdateQuestion(updatedQuestion, true, file);
  };

  const handleClearImage = () => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.image_file = undefined;
    updatedQuestion.image_url = undefined;
    onUpdateQuestion(updatedQuestion, true);
  };

  return (
    <div className="multi-part-assignment-component">
      <div className="user-input-label" style={{ alignSelf: 'stretch' }}>
        <label className="label-small" htmlFor={`${question.index}-prompt`}>
          Question {question.index}
        </label>
      </div>
      <textarea
        id={`${question.index}-prompt`}
        placeholder="Type your reading here"
        value={question.text}
        onChange={(e) => handleUpdatePrompt(e.target.value)}
        style={{ width: '100%', height: '100%' }}
        maxLength={800}
      />
      <AddImageButton
        imageUrl={question.image_url}
        imageFile={question.image_file}
        onAddImage={handleAddImage}
        onClearImage={handleClearImage}
      />
    </div>
  );
};

export default MultiPartReadAloudFrame;

import React from 'react';
import MissedWordTable from './MissedWordTable';
import { DisplayMissedWord } from 'utils/types';

interface SubmissionAnalyticsProps {
  missedWords: DisplayMissedWord[];
}

const SubmissionAnalytics: React.FC<SubmissionAnalyticsProps> = ({
  missedWords,
}) => {
  return (
    <>
      <div className="graph-container">
        {missedWords && <MissedWordTable missedWords={missedWords} />}
      </div>
    </>
  );
};

export default SubmissionAnalytics;

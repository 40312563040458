import React, { useEffect, useState } from 'react';
import Book from 'models/Book';
import QuestionResponse from 'models/QuestionResponse';
import { BookReader } from 'view/components/common/BookReader';
import { FrameProps } from './MultipartAssignment';
import Question from 'models/Question';
import useApiCall from 'contexts/ApiCall';
import teacherService from 'services/teacherService';
import MissedWord from 'models/MissedWord';
import QuestionFeedback from './QuestionFeedback';

interface ReadAloudFrameProps extends FrameProps {
  onMissedWord?: (
    word: string,
    index: number,
    known: boolean,
    question?: Question,
  ) => Promise<void>;
}
const ReadAloudFrame: React.FC<ReadAloudFrameProps> = ({
  question,
  response,
  teacherView = false,
  onMissedWord = () => {},
  updateResponse,
}) => {
  const [book, setBook] = useState<Book>();
  const [missedWords, setMissedWords] = useState<MissedWord[]>();
  const [isReading, setIsReading] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  useEffect(() => {
    var book = Book.generateDefault();
    book.html_content = `<p>${question.text}</p>`;
    book.words = book.getWords();
    setBook(book);
  }, [question.text]);

  useEffect(() => {
    if (missedWords && missedWords.length) return;
    makeApiCall(
      teacherService.getMissedWords,
      null,
      null,
      response.assignment_submission,
      question.id,
    )
      .then((resp) => setMissedWords(resp))
      .catch((error) => alert('Failed to fetch missed words'));
  }, [question, response, missedWords, setMissedWords, makeApiCall]);

  const handleUpdateResponse = (index?: number) => {
    if (teacherView || !book) return;

    const numWords = book.getWords().length;

    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = index
      ? 'Reading in progress'
      : 'Reading completed';
    updatedResponse.grade = index
      ? Number(Math.ceil(index / numWords) * 100)
      : 100;
    updatedResponse.num_attempts += 1;
    updatedResponse.completed = index ? false : true;
    updatedResponse.response = index?.toString() ?? numWords.toString();

    updateResponse(updatedResponse);
  };

  const handleMissedWord = (word: string, index: number, known: boolean) => {
    onMissedWord(word, index, known, question);
  };

  const handleGradeResponse = (grade: number, feedback: string) => {
    if (!teacherView) return;

    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = grade;

    updateResponse(updatedResponse);
  };

  return (
    <>
      {book && response && (
        <BookReader
          missedWords={missedWords}
          book={book}
          startIndex={response.response !== '' ? Number(response.response) : 0}
          isReading={isReading}
          setIsReading={setIsReading}
          onMissedWord={handleMissedWord}
          showMissedWordPopup={true}
          onCompletion={handleUpdateResponse}
          onStopReading={handleUpdateResponse}
        />
      )}
      {(teacherView || (!teacherView && response.completed)) && (
        <QuestionFeedback
          feedback={response.feedback ?? ''}
          grade={response.grade}
          teacherView={teacherView}
          onGradeResponse={handleGradeResponse}
        />
      )}
    </>
  );
};

export default ReadAloudFrame;

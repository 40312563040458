export default class Module {
  public readonly id;
  public name: string;
  public index: number;
  public classroom?: string;
  public reading_difficulty?: string;
  public tags?: string[];
  public key_words?: string[];

  constructor(
    id: string,
    name: string,
    index: number,
    classroom?: string,
    reading_difficulty?: string,
    tags?: string[],
    key_words?: string[],
  ) {
    this.id = id;
    this.name = name;
    this.index = index;
    this.classroom = classroom;
    this.reading_difficulty = reading_difficulty;
    this.tags = tags;
    this.key_words = key_words;
  }

  static fromModule(module: Module) {
    return new Module(
      module.id,
      module.name,
      module.index,
      module.classroom,
      module.reading_difficulty,
      module.tags,
      module.key_words,
    );
  }

  static fromServerModule(module: Record<string, any>): Module {
    return new Module(
      module['id'],
      module['name'],
      module['index'],
      module['classroom'],
      module['reading_difficulty'],
      module['tags'],
      module['key_words'],
    );
  }
}

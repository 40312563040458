import User from 'models/User';
import apiService from 'services/apiService';
import Classroom from 'models/Classroom';
import { parseError } from 'utils/utils';
import StudentProfile from 'models/StudentProfile';

type GetClassroomResponse = {
  classroom: Classroom;
  student?: StudentProfile;
};

export class UserService {
  updateUserProfile = async (request: Record<string, any>): Promise<User> => {
    try {
      const response = await apiService.api.patch(
        `/users/${request.id}/`,
        request,
      );
      return User.fromServerUser({
        ...response.data.user,
        token: response.data.token,
        refresh_token: response.data.refresh,
      });
    } catch (error) {
      console.error('Network error');
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getUserClassrooms = async (user: User): Promise<Array<Classroom>> => {
    try {
      const response = await apiService.api.get(
        `/users/${user.getId()}/${user.account_type.toLowerCase()}_classroom_list/`,
      );
      return response.data.map((classroom: Record<string, any>) =>
        Classroom.fromServerClassroom(classroom),
      );
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getClassroomById = async (
    classroom_id: string,
  ): Promise<GetClassroomResponse> => {
    try {
      const response = await apiService.api.get(`/classrooms/${classroom_id}/`);
      return {
        classroom: Classroom.fromServerClassroom(response.data.classroom),
        student: response.data.student
          ? StudentProfile.fromServerStudentProfile(response.data.student)
          : undefined,
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateUser = async (user: User): Promise<User> => {
    try {
      const response = await apiService.api.patch(`/users/${user.getId()}/`, {
        ...user,
      });
      return User.fromServerUser(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  deleteUser = async (userId: string): Promise<any> => {
    try {
      const response = await apiService.api.delete(`/users/${userId}/`);
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const userService = new UserService();
export default userService;

import React, { useEffect, useState } from 'react';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import QuestionResponse from 'models/QuestionResponse';
import { FrameProps } from './MultipartAssignment';
import QuestionChoices from '../assignment/QuestionChoices';
import QuestionFeedback from './QuestionFeedback';

import 'view/style/student/components/newcomer/multipartAssignment.css';

const FillInBlankFrame: React.FC<FrameProps> = ({
  question,
  response,
  teacherView = false,
  updateResponse,
}) => {
  const [questionText, setQuestionText] = useState<string[]>();

  useEffect(() => {
    setQuestionText(question.text.split('_').filter((t) => t !== ''));
  }, [question.text]);

  useEffect(() => {
    if (!question.check || teacherView) return;
    if (question.choices && question.choices.length) {
      // update the response
      handleUpdateResponse(
        response.response,
        response.choice,
        response.grade > 0,
        response.feedback,
        true,
      );
      return;
    }
    // eslint-disable-next-line
  }, [question.check, teacherView]);

  const handleUpdateResponse = (
    responseText: string,
    responseChoice: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    if ((response.num_attempts > 0 && response.completed) || teacherView)
      return;
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.response = responseText;
    updatedResponse.choice = responseChoice;
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = updatedResponse.completed || isAttempt;

    updateResponse(updatedResponse);
  };

  const handleSelectChoice = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (response.num_attempts > 0 || response.completed || teacherView) return;
    const choice = question.choices?.find(
      (c) => Number(c.id) === Number(event.target.value),
    );
    if (!choice || Number(choice.id) === Number(response.choice)) return;
    handleUpdateResponse(
      '',
      event.target.value,
      choice.correct,
      response.feedback,
      response.completed,
    );
  };

  const handleGradeResponse = (grade: number, feedback: string) => {
    if (!teacherView) return;

    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = grade;

    updateResponse(updatedResponse);
  };

  if (!questionText) return null;

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          <span className="label-medium">{questionText[0]} </span>
          <select value={response.choice} onChange={handleSelectChoice}>
            {question.choices!.map((c) => (
              <option key={`dropdown-${c.id}`} value={c.id}>
                {c.text}
              </option>
            ))}
          </select>{' '}
          <span className="label-medium">{questionText[1]}</span>
          <AudioAssistanceButton
            text={`${questionText[0]} blank ${questionText[1]}`}
          />
        </div>
        {question.choices && (
          <QuestionChoices
            choice={response.choice}
            choices={question.choices}
            highlightCorrect={question.check}
            onUpdateResponse={handleUpdateResponse}
          />
        )}
        {(teacherView || (!teacherView && response.completed)) && (
          <QuestionFeedback
            feedback={response.feedback ?? ''}
            grade={response.grade}
            teacherView={teacherView}
            onGradeResponse={handleGradeResponse}
          />
        )}
      </div>
    </div>
  );
};

export default FillInBlankFrame;

import { parseError } from 'utils/utils';
import apiService from 'services/apiService';
import Module from 'models/Module';
import Classroom from 'models/Classroom';

export class AcademyService {
  private static instance: AcademyService;

  public static get(): AcademyService {
    if (!AcademyService.instance) {
      AcademyService.instance = new AcademyService();
    }

    return AcademyService.instance;
  }

  getAcademy = async (user: string, id: string): Promise<Classroom> => {
    try {
      const response = await apiService.api.get('/classrooms/public');
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  joinAcademy = async (user: string, id: string): Promise<Classroom> => {
    try {
      const response = await apiService.api.post('/students/', {
        user: user,
        classroom: id,
        access_code: 'password',
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getModules = async (classroomId: string): Promise<Module[]> => {
    try {
      const response = await apiService.api.get(
        `/classrooms/${classroomId}/modules`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getModule = async (moduleId: string): Promise<Module> => {
    try {
      const response = await apiService.api.get(`/modules/${moduleId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getModuleAssignments = async (moduleId: string): Promise<Module> => {
    try {
      const response = await apiService.api.get(
        `/modules/${moduleId}/assignments`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const academyService = AcademyService.get();
export default academyService;

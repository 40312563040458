import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import StudentGradebook from 'models/StudentGradebook';
import studentService from 'services/studentService';
import StudentProfile from 'models/StudentProfile';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useNavigate, useParams } from 'react-router-dom';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import { StatusIcon } from 'view/components/common/StatusIcon';
import { useAssignmentContext } from 'contexts/AssignmentContext';
import GradebookEntry from 'models/StudentGradebookEntry';
import { formatDate } from 'utils/utils';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import { AppPage } from 'view/components/common/AppPage';
import userService from 'services/userService';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import ItemPanel from 'view/components/common/ItemPanel';
import SidebarButton from 'view/components/buttons/SidebarButton';
import { SearchBar } from 'view/components/common/SearchBar';

import 'view/style/student/classroom.css';

const arrow: string = require('assets/icons/arrow-forward.svg').default;
const school: string = require('assets/icons/school_building.svg').default;

export const StudentClassPage: React.FC = () => {
  const { user } = useUserContext();
  const { classroom, setClassroom } = useClassroomContext();
  const { classroomId } = useParams();
  const { setAssignment } = useAssignmentContext();
  const [gradebook, setGradebook] = useState<StudentGradebook>();
  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [previewEntry, setPreviewEntry] = useState<StudentGradebookEntry>();
  const pageContent = contentService.getStudentClassroomPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || classroom || !classroomId) return;
    makeApiCall(userService.getClassroomById, classroomId)
      .then((resp) => {
        setClassroom(resp.classroom);
        setStudentProfile(resp.student);
      })
      .catch((error) => navigate(-1));
  }, [user, classroom, classroomId, navigate, setClassroom, makeApiCall]);

  useEffect(() => {
    if (studentProfile || !user || !classroom) return;
    makeApiCall(studentService.getStudentProfile, user, classroom)
      .then((respStudentProfile) => setStudentProfile(respStudentProfile))
      .catch((error) => alert(error.message));
  }, [studentProfile, user, classroom, makeApiCall]);

  useEffect(() => {
    if (gradebook || !studentProfile || !user || !classroom) return;
    makeApiCall(
      studentService.getGradebook,
      studentProfile.id,
      classroom?.getId(),
    )
      .then((respGradebook) => setGradebook(respGradebook))
      .catch((error) => alert(error.message));
  }, [gradebook, user, studentProfile, classroom, makeApiCall]);

  const handleAssignmentSelect = (entry: GradebookEntry) => {
    if (previewEntry === entry) {
      setAssignment(entry);
      navigate(`assignment/${entry.assignment.getId()}/`);
    }
    setPreviewEntry(entry);
  };

  const handleExitClassroom = () => {
    setClassroom(null);
    navigate('/student/home/');
  };

  const filterGradebook = (searchTerm: string) => {
    if (!gradebook) return;
    const filteredGradebook = StudentGradebook.fromStudentGradebook(gradebook);
    filteredGradebook.entries.forEach(
      (entry) =>
        (entry.visible = entry.assignment.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase())),
    );
    setGradebook(filteredGradebook);
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleExitClassroom}>
          <>
            <SearchBar onSearch={filterGradebook} />
            <div className="sidebar-content-data">
              <p>{pageContent.sidebar.average.title}</p>
              <b>{gradebook ? gradebook.averages.grade : 0}%</b>
            </div>
            {previewEntry && (
              <div className="sidebar-content-data">
                <div className="assignment-details-container">
                  <div className="assignment-detail-header">
                    <label className="label-small">
                      {pageContent.sidebar.assignment_preview.title}
                    </label>
                    <AudioAssistanceButton text="Selected Assignment" />
                  </div>
                  <div className="assignment-details-list">
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[0]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment_submission.grade}%
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[1]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment_submission.completion_score}%
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[2]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment.due_date
                          ? formatDate(
                              new Date(previewEntry.assignment.due_date),
                            )
                          : 'No due date'}
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[3]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment.start_date
                          ? formatDate(
                              new Date(previewEntry.assignment.start_date),
                            )
                          : 'Any time'}
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[4]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment_submission.questions_answered}/
                        {previewEntry.assignment.num_questions}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <SidebarButton
              icon={school}
              label="Practice"
              text="practice"
              onClick={() => navigate(`practice/${studentProfile?.id}`)}
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>{classroom?.name}</h1>
          <div className="item-list">
            {gradebook?.entries
              .filter((entry) => entry.visible)
              .map((entry) => (
                <ItemPanel
                  key={`gradebook-entry-${entry.assignment_submission.getId()}`}
                  id={`gradebook-entry-${entry.assignment_submission.getId()}`}
                  selected={entry === previewEntry}
                  onClick={() => handleAssignmentSelect(entry)}
                  onDoubleClick={() => handleAssignmentSelect(entry)}
                >
                  <div className="row">
                    <label className="label-normal">
                      {entry.assignment.title}
                    </label>
                    {['Flashcards', 'Pronounce'].includes(
                      entry.assignment.assignment_type,
                    ) && <StatusIcon type="event" text={'Practice'} />}
                    {entry.assignment.assignment_type === 'Listening' ||
                      (entry.assignment.assignment_type === 'Read-Aloud' && (
                        <StatusIcon type="listening" text="Listening" />
                      ))}
                  </div>
                  <div className="row">
                    {entry.assignment_submission.date_completed ? (
                      !entry.assignment.due_date ||
                      new Date(entry.assignment_submission.date_completed) <=
                        new Date(entry.assignment.due_date) ? (
                        <StatusIcon
                          type="done"
                          text={pageContent.constants.status_icons.done.text}
                        />
                      ) : (
                        <div className="row">
                          <StatusIcon
                            type="alert"
                            text={pageContent.constants.status_icons.late.text}
                          />
                          <StatusIcon
                            type="done"
                            text={pageContent.constants.status_icons.done.text}
                          />
                        </div>
                      )
                    ) : !entry.assignment.due_date ||
                      new Date(entry.assignment.due_date) > new Date() ? (
                      <StatusIcon
                        type="todo"
                        text={pageContent.constants.status_icons.todo.text}
                      />
                    ) : (
                      <StatusIcon
                        type="alert"
                        text={pageContent.constants.status_icons.overdue.text}
                      />
                    )}
                    {entry === previewEntry && (
                      <>
                        {pageContent.content.selected_assignment.text}
                        <img src={arrow} alt="" />
                      </>
                    )}
                  </div>
                </ItemPanel>
              ))}
          </div>
        </div>
      </div>
    </AppPage>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import Module from 'models/Module';
import academyService from 'services/academyService';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import Assignment from 'models/Assignment';
import AssignmentSubmission from 'models/AssignmentSubmission';
import ItemPanel from 'view/components/common/ItemPanel';
import { WordList } from 'view/components/common/WordList';

const ModulePage: React.FC = () => {
  const [selectedAssignment, setSelectedAssignment] =
    useState<StudentGradebookEntry>();
  const [assignments, setAssignments] = useState<StudentGradebookEntry[]>();
  const [module, setModule] = useState<Module>();
  const { moduleId } = useParams();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    makeApiCall(academyService.getModule, moduleId)
      .then((resp) => setModule(resp))
      .catch((err) => alert(err));

    makeApiCall(academyService.getModuleAssignments, moduleId)
      .then((resp) =>
        setAssignments(
          resp.map((a: Assignment) => {
            return {
              assignment: a,
              submission: AssignmentSubmission.generateDefault(a.id),
            };
          }),
        ),
      )
      .catch((err) => alert(err));
  }, [moduleId, makeApiCall]);

  if (!module) return <AppPage />;

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={() => navigate(-1)}>
          <div className="sidebar-content-data">
            <div className="row">
              <label className="label-normal">
                Module {module.index + 1}: {module.name}
              </label>
              <AudioAssistanceButton
                text={`Module ${module.index}: ${module.name}`}
              />
            </div>
          </div>
          {module.key_words && module.key_words.length > 0 && (
            <div className="sidebar-content-data">
              <div className="row">
                <label className="label-normal">Key words:</label>
                <AudioAssistanceButton text="Key words" />
              </div>
              <WordList words={module.key_words} />
            </div>
          )}
        </AppSidebar>
        <div className="app-main-content">
          <h1>
            {' '}
            Module {module.index + 1}: {module.name}
          </h1>
          <div className="item-list">
            {assignments?.map((a: StudentGradebookEntry) => (
              <ItemPanel
                selected={a === selectedAssignment}
                key={`assignment-${a.assignment.id}`}
                id={`assignment-${a.assignment.id}`}
                onClick={() => setSelectedAssignment(a)}
                onDoubleClick={() => navigate(`assignment/${a.assignment.id}`)}
              >
                {a.assignment.title}
              </ItemPanel>
            ))}
          </div>
        </div>
      </div>
    </AppPage>
  );
};

export default ModulePage;

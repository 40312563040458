import useApiCall from 'contexts/ApiCall';
import QuestionResponse from 'models/QuestionResponse';
import React, { useEffect, useState } from 'react';
import googleApiService from 'services/googleAPIService';
import CachingEngine from 'utils/CachingEngine';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import { FrameProps } from './MultipartAssignment';
import ReadingUtils from 'utils/ReadingUtils';
import SpeechBubble from 'view/components/common/SpeechBubble';
import QuestionFeedback from './QuestionFeedback';

const wormy_a: string = require('assets/images/logos/wormy-1.png');

function cleanSentence(sentence: string): string {
  return sentence
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase()
    .trim();
}

const TranscribeFrame: React.FC<FrameProps> = ({
  question,
  response,
  teacherView = false,
  updateResponse,
}) => {
  // eslint-disable-next-line
  const [audioSrc, setAudioSrc] = useState();
  const makeApiCall = useApiCall();

  useEffect(() => {
    // check the student's short answer response
    if (question.check && !teacherView) {
      var correct = true;
      const promptWords = cleanSentence(question.text).split(' ');
      const responseWords = cleanSentence(response.response).split(' ');
      if (promptWords.length === responseWords.length) {
        for (let i = 0; i < promptWords.length; i++) {
          if (
            !ReadingUtils.soundsSimilar(promptWords[i], responseWords[i], 0.9)
          ) {
            correct = false;
            break;
          }
        }
      } else {
        correct = false;
      }
      handleUpdateResponse(response.response, correct, undefined, true);
    }

    if (question.text && !audioSrc) {
      CachingEngine.getData(`${question.index}-conversation`).then(
        (cachedAudio) => {
          if (cachedAudio) {
            setAudioSrc(cachedAudio);
          } else {
            makeApiCall(googleApiService.convertTextToSpeech, question.text)
              .then((resp) => {
                setAudioSrc(resp);
                CachingEngine.setData(`${question.index}-conversation`, resp);
              })
              .catch((error) =>
                console.error(`Failed to generate audio: ${error}`),
              );
          }
        },
      );
    }

    // eslint-disable-next-line
  }, [question.check, question.text, teacherView, makeApiCall]);

  const handleUpdateResponse = (
    responseText: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    if (teacherView) return;
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = updatedResponse.completed || isAttempt;
    updatedResponse.response = responseText;

    updateResponse(updatedResponse);
  };

  const handleGradeResponse = (grade: number, feedback: string) => {
    if (!teacherView) return;

    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = grade;

    updateResponse(updatedResponse);
  };

  return (
    <div className="conversation-frame">
      <div className="row">
        <img className="wormy" src={wormy_a} alt="wormy" />
        <AudioAssistanceButton text={question.text} audioSrc={audioSrc} />
      </div>
      {question.check && <SpeechBubble text={question.text} />}
      <label htmlFor="question-text-area">Write what you hear</label>
      <div className="row spaced">
        <textarea
          id="question-text-area"
          value={response.response ?? ''}
          disabled={response.completed}
          onChange={(event) =>
            handleUpdateResponse(event.target.value, false, undefined, false)
          }
        />
        {question.image_url && (
          <div className="book-card-cover">
            <img src={question.image_url} alt="failed to load" />
          </div>
        )}
      </div>
      {(teacherView || (!teacherView && response.completed)) && (
        <QuestionFeedback
          feedback={response.feedback ?? ''}
          grade={response.grade}
          teacherView={teacherView}
          onGradeResponse={handleGradeResponse}
        />
      )}
    </div>
  );
};

export default TranscribeFrame;

import React from 'react';
import QuestionResponse from 'models/QuestionResponse';
import { Grid } from '@mui/material';
import ImageChoice from '../ImageChoice';
import MultipleChoiceOption from 'models/MultipleChoiceOption';

type VisualMultipleChoiceOptionsProps = {
  check: boolean;
  choices: MultipleChoiceOption[];
  response: QuestionResponse;
  onUpdateResponse: (
    responseChoice: string,
    correct: boolean,
    feedback?: string,
    isAttempt?: boolean,
  ) => void;
};

const VisualMultipleChoiceOptions: React.FC<
  VisualMultipleChoiceOptionsProps
> = ({ check, choices, response, onUpdateResponse }) => {
  return (
    <>
      {choices?.length && (
        <Grid container>
          {choices.map((choice) => (
            <Grid key={`image-choice-${choice.id}`} item>
              <ImageChoice
                key={`choice-${choice.id}`}
                id={`choice-${choice.id}`}
                selected={
                  check
                    ? choice.correct
                    : Number(response.choice) === Number(choice.id)
                }
                onClick={() =>
                  response.num_attempts === 0 &&
                  !response.completed &&
                  onUpdateResponse(
                    choice.id,
                    choice.correct,
                    response.feedback,
                    false,
                  )
                }
                onDoubleClick={() =>
                  response.num_attempts === 0 &&
                  !response.completed &&
                  onUpdateResponse(
                    choice.id,
                    choice.correct,
                    response.feedback,
                    false,
                  )
                }
                highlight={choice.correct && check}
                incorrect={
                  !choice.correct &&
                  Number(response.choice) === Number(choice.id) &&
                  check
                }
              >
                <img src={choice.img} alt="" />
              </ImageChoice>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default VisualMultipleChoiceOptions;

import React, { useEffect, useState } from 'react';
import { AppPage } from '../../../components/common/AppPage';
import AppSidebar from 'view/components/common/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { MissedWordList } from 'view/components/student/MissedWordList';
import MissedWord from 'models/MissedWord';
import useApiCall from 'contexts/ApiCall';
import studentService from 'services/studentService';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import Modal from 'view/components/common/Modal';
import ReadingPractice from 'view/components/student/practice/ReadingPractice';
import PronouncePractice from 'view/components/student/practice/PronouncePractice';
import FlashcardPractice from 'view/components/student/practice/FlashcardPractice';
import LanguageSelector from 'view/components/common/LanguageSelector';
import StudentGradebookEntry from 'models/StudentGradebookEntry';

import 'view/style/student/practice.css';

export enum PracticeType {
  READING = 'Read',
  PRONOUNCE = 'Pronounce',
  FLASHCARD = 'Flashcards',
}

const graduation: string = require('assets/icons/graduation_cap.svg').default;
const arrow: string = require('assets/icons/arrow-forward.svg').default;

const StudentPracticePage: React.FC = () => {
  const [missedWords, setMissedWords] = useState<MissedWord[]>();
  const [keyWords, setKeyWords] = useState<MissedWord[]>();
  const [practiceType, setPracticeType] = useState<PracticeType | null>(null);
  const [practiceComps, setPracticeComps] = useState<
    Record<string, JSX.Element>
  >({});
  const { classroom } = useClassroomContext();
  const { studentId } = useParams();
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (missedWords !== undefined || !studentId) return;
    makeApiCall(studentService.getStudentMissedWords, studentId)
      .then((respWords) => {
        setMissedWords(respWords);
      })
      .catch((error) => alert(error.message));
  }, [missedWords, studentId, makeApiCall]);

  useEffect(() => {
    if (keyWords !== undefined || !studentId || !classroom) return;
    makeApiCall(studentService.getGradebook, studentId, classroom?.getId())
      .then((respGradebook) =>
        setKeyWords(
          respGradebook.entries.flatMap((entry: StudentGradebookEntry) =>
            entry.assignment.key_words.map(
              (keyWord) => new MissedWord('-1', keyWord, '-1', -1, false, true),
            ),
          ),
        ),
      )
      .catch((error) => alert(error.message));
  }, [keyWords, studentId, classroom, makeApiCall]);

  useEffect(() => {
    if (missedWords === undefined || missedWords.length === 0) return;
    setPracticeComps({
      [PracticeType.READING]: <ReadingPractice missedWords={missedWords} />,
      [PracticeType.PRONOUNCE]: <PronouncePractice missedWords={missedWords} />,
      [PracticeType.FLASHCARD]: <FlashcardPractice missedWords={missedWords} />,
    });
  }, [missedWords]);

  const handlePracticeModeChanged = (event: any) => {
    const practiceMode = event.target.value;
    switch (practiceMode) {
      case 'missedWord':
        if (!missedWords) return;
        setPracticeComps({
          [PracticeType.READING]: <ReadingPractice missedWords={missedWords} />,
          [PracticeType.PRONOUNCE]: (
            <PronouncePractice missedWords={missedWords} />
          ),
          [PracticeType.FLASHCARD]: (
            <FlashcardPractice missedWords={missedWords} />
          ),
        });
        break;
      case 'keyWord':
        if (!keyWords) return;
        setPracticeComps({
          [PracticeType.READING]: <ReadingPractice missedWords={keyWords} />,
          [PracticeType.PRONOUNCE]: (
            <PronouncePractice missedWords={keyWords} />
          ),
          [PracticeType.FLASHCARD]: (
            <FlashcardPractice missedWords={keyWords} />
          ),
        });
        break;
      default:
        alert(`Invalid practice mode selected: ${practiceMode}`);
    }
  };

  if (!classroom) return <AppPage />;

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar
          onExit={() => navigate(`/student/classroom/${classroom.id}`)}
        >
          <>
            <LanguageSelector />
            <div className="row spaced">
              <div className="row">
                <img src={graduation} alt="" />
                <label className="label-small">Review Words</label>
                <AudioAssistanceButton text="Review words" />
              </div>
            </div>
            {missedWords && <MissedWordList missedWords={missedWords} />}
            <div className="row spaced">
              <div className="row">
                <img src={graduation} alt="" />
                <label className="label-small">Key Words</label>
                <AudioAssistanceButton text="Key words" />
              </div>
            </div>
            {keyWords && <MissedWordList missedWords={keyWords} />}
            <div className="row spaced">
              <div className="row">
                <img src={graduation} alt="" />
                <label className="label-small">Practice Type</label>
                <AudioAssistanceButton text="Key words" />
              </div>
            </div>
            <select onChange={handlePracticeModeChanged}>
              <option value="missedWord">Review Words</option>
              <option value="keyWord">Key Words</option>
            </select>
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>Practice Hub</h1>
          <div className="classroom-list">
            {Object.values(PracticeType).map((pt) => (
              <div
                key={pt}
                className="classroom-panel"
                onClick={() => setPracticeType(pt)}
              >
                <h3>{pt}</h3>
                <img src={arrow} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      {Object.values(PracticeType).map((pt) => (
        <Modal
          isOpen={pt === practiceType}
          onClose={() => setPracticeType(null)}
          // style={{ width: '100%', height: '100%' }}
        >
          {practiceComps[pt]}
        </Modal>
      ))}
    </AppPage>
  );
};

export default StudentPracticePage;

import { doubleMetaphone } from 'double-metaphone';
import { diceCoefficient } from 'dice-coefficient';
import { syllable } from 'syllable';
import { SpeechMode } from './types';
// import stemmer from 'stemmer';

export default class ReadingUtils {
  public static specialCharacterRegex: RegExp = /[^a-zA-Z0-9'’]+/;

  public static stripSpecialCharsFromEndsOfString(inputString: string) {
    let start = 0;
    let end = inputString.length;
    for (let i = 0; i < inputString.length; i += 1) {
      if (ReadingUtils.specialCharacterRegex.test(inputString[i])) {
        start += 1;
      } else break;
    }
    for (let i = inputString.length - 1; i >= 0; i -= 1) {
      if (ReadingUtils.specialCharacterRegex.test(inputString[i])) {
        end -= 1;
      } else break;
    }
    return inputString.slice(start, end);
  }

  public static getFirstWordIndexFromHTMLString(
    htmlString: string,
    startIndex: number = 0,
  ): number {
    let openCount = 0;
    for (let i = startIndex; i < htmlString.length; i += 1) {
      if (htmlString[i] === '<') openCount += 1;
      else if (htmlString[i] === '>') openCount -= 1;
      else if (
        !openCount &&
        !ReadingUtils.specialCharacterRegex.test(htmlString[i])
      ) {
        return i;
      }
    }
    return 0;
  }

  public static createHtmlString(
    words: string[],
    inputHtmlString: string,
  ): string {
    const htmlString = inputHtmlString.replaceAll('&nbsp;', ' ');
    var htmlIndex = 0;
    const resultString = [];
    for (let i = 0; i < words.length; i += 1) {
      const wordOpenTag = `<span id='word-${i}' className='brw${
        i === 0 ? ' current' : ''
      }'>`;
      const wordCloseTag = '</span>';

      // get the start index of the word in the HTML string
      const htmlWordIdx = ReadingUtils.getFirstWordIndexFromHTMLString(
        htmlString,
        htmlIndex,
      );

      // get the end index of the word in the HTML string
      const htmlWordEndIdx = htmlWordIdx + words[i].length;

      const substring = `${htmlString.substring(
        htmlIndex,
        htmlWordIdx,
      )}${wordOpenTag}${htmlString.substring(
        htmlWordIdx,
        htmlWordEndIdx,
      )}${wordCloseTag}`;
      resultString.push(substring);

      htmlIndex = htmlWordEndIdx;
    }
    resultString.push(htmlString.substring(htmlIndex));

    return resultString.join('');
  }

  public static compare(
    spokenWord: string,
    actualWord: string,
    speechMode: SpeechMode,
  ) {
    switch (speechMode) {
      case SpeechMode.Easy:
        return this.soundsSimilar(spokenWord, actualWord, 0.5);
      case SpeechMode.Close:
        return this.soundsSimilar(spokenWord, actualWord);
      case SpeechMode.Strict:
        return this.soundsSimilar(spokenWord, actualWord, 0.9);
    }
  }

  public static soundsSimilar(
    spokenWord: string,
    actualWord: string,
    threshold: number = 0.65,
  ) {
    // console.log(spokenWord, actualWord);
    if (spokenWord === actualWord) return true;
    const phoneticsWord1 = doubleMetaphone(spokenWord);
    const phoneticsWord2 = doubleMetaphone(actualWord);
    return phoneticsWord1
      .map((pw: string) =>
        phoneticsWord2
          .map((pw2: string) => diceCoefficient(pw, pw2))
          .some((res) => res >= threshold),
      )
      .some((res) => res);
  }

  public static compareSyllableCount(word1: string, word2: string) {
    return syllable(word1) === syllable(word2);
  }
}

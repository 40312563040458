import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PasswordInput from 'view/components/common/PasswordInput';
import { UserInput } from 'view/components/common/UserInput';
import Button from 'view/components/buttons/Button';
import contentService from 'services/contentService';

import 'view/style/home/login.css';
import 'view/style/home/home.css';

const logo: string = require('assets/images/logos/official_logo.png');

type LoginFormProps = {
  errorMessage?: string;
  infoMessage?: string;
  signupAddendum?: string;
  onLogin: (username: string, password: string) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  errorMessage,
  infoMessage,
  signupAddendum,
  onLogin,
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const loginContent = contentService.getLoginPage();
  const navigate = useNavigate();

  const handleLogin = () => {
    onLogin(username, password);
  };

  return (
    <div className="login">
      <div className="login-title">
        <img src={logo} alt="" />
        <h2>{loginContent.content.title}</h2>
      </div>
      <div className="login-content">
        <UserInput
          id="login-username"
          label="Username"
          type="text"
          text="username"
          onChange={(event) => setUsername(event.target.value)}
        />
        <PasswordInput
          password={password}
          setPassword={setPassword}
          verify={false}
          label="Password"
          text="password"
        />
        {errorMessage && (
          <span className="label-normal danger">{errorMessage}</span>
        )}
        <div className="row">
          <Button
            onClick={() => navigate('/')}
            text="Back"
            label={loginContent.content.cancel_button.label}
          />
          <Button
            onClick={handleLogin}
            text="Log in"
            label={loginContent.content.confirm_button.label}
          />
        </div>
      </div>
      <div className="login-footer">
        <div id="googleSigninDiv"></div>
        {infoMessage && (
          <span className="label-normal hint">{infoMessage}</span>
        )}
        {loginContent.footer.links.map((link: Record<string, string>) => (
          <Link key={`link-${link.text}`} to={`${link.path}${signupAddendum}`}>
            {link.text}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LoginForm;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useAssignmentContext } from 'contexts/AssignmentContext';
import Book from 'models/Book';
import studentService from 'services/studentService';
import MissedWord from 'models/MissedWord';
import Modal from 'view/components/common/Modal';
import {
  QuestionViewerState,
  QuestionsViewer,
} from 'view/components/student/QuestionsViewer';
import StudentQuestion from 'models/StudentQuestion';
import { formatDate } from 'utils/utils';
import QuestionResponse from 'models/QuestionResponse';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import { MissedWordList } from 'view/components/student/MissedWordList';
import StudentGradebook from 'models/StudentGradebook';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import CachingEngine from 'utils/CachingEngine';
import ReadingUtils from 'utils/ReadingUtils';
import LanguageSelector from 'view/components/common/LanguageSelector';
import AssignmentSettings from 'view/components/student/assignment/AssignmentSettings';
import Question from 'models/Question';
import AssignmentContent from 'view/components/student/assignment/AssignmentContent';
import { calculateKeyWordAccuracy } from 'utils/assignmentUtils';
import { QuestionType } from 'utils/types';

import 'view/style/student/assignment.css';

const calendar: string = require('assets/icons/event.svg').default;
const graduation: string = require('assets/icons/graduation_cap.svg').default;
const eye: string = require('assets/icons/eye-solid.svg').default;

const useStopwatch = (isReading: boolean, initialValue?: number) => {
  const [elapsedTime, setElapsedTime] = useState<number>(initialValue ?? 0);
  const countRef = useRef<number | null>(null);

  const startTimer = () => {
    const startTime = Date.now() - elapsedTime;
    countRef.current = window.setInterval(() => {
      setElapsedTime(Math.round((Date.now() - startTime) / 1000));
    }, 1000) as unknown as number;
  };

  const pauseTimer = () => {
    if (countRef.current !== null) {
      clearInterval(countRef.current);
      countRef.current = null;
    }
  };

  useEffect(() => {
    if (isReading) {
      startTimer();
    } else {
      pauseTimer();
    }

    return pauseTimer;
    // eslint-disable-next-line
  }, [isReading]);

  return elapsedTime;
};

export const AssignmentSubmissionPage: React.FC = () => {
  const { classroom } = useClassroomContext();
  const { assignment, setAssignment } = useAssignmentContext();
  const { classroomId, assignmentId } = useParams();
  const [book, setBook] = useState<Book>();
  const [missedWords, setMissedWords] = useState<MissedWord[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<StudentQuestion[]>();
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const [isReading, setIsReading] = useState<boolean>(false);
  const [isOverdue, setIsOverdue] = useState<boolean>(false);
  const [useDyslexic, setUseDyslexic] = useState<boolean>(false);
  const [viewSettings, setViewSettings] = useState<boolean>(false);
  const elapsedTime = useStopwatch(
    isReading,
    assignment?.assignment_submission.completion_time,
  );
  const pageContent = contentService.getAssignmentSubmissionPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!classroom || !assignmentId || book || questions) return;
    makeApiCall(studentService.getAssignmentById, assignmentId, assignment)
      .then((respAssignment) => {
        setAssignment(respAssignment.assignment);
        setBook(respAssignment.book);
        setQuestions(respAssignment.questions);
        setIsOverdue(
          new Date(respAssignment.assignment.assignment.due_date) < new Date(),
        );
      })
      .catch((error) => navigate(-1));
  }, [
    assignment,
    book,
    questions,
    classroom,
    classroomId,
    assignmentId,
    setAssignment,
    setBook,
    setQuestions,
    navigate,
    makeApiCall,
  ]);

  useEffect(() => {
    if (missedWords.length > 0 || !assignment || !isLoading) return;
    setIsLoading(true);
    if (
      assignment.assignment.assignment_type === 'Flashcards' ||
      assignment.assignment.assignment_type === 'Pronounce'
    ) {
      if (assignment.assignment.key_words.length) {
        setMissedWords(
          assignment.assignment.key_words.map(
            (kw) => new MissedWord('-1', kw, '-1', 0, false, true),
          ),
        );
      } else {
        makeApiCall(
          studentService.getStudentMissedWords,
          assignment?.assignment_submission.student_profile,
        )
          .then((respWords) =>
            setMissedWords(respWords.slice(0, assignment.assignment.length)),
          )
          .catch((error) => alert(error.message))
          .finally(() => setIsLoading(false));
      }
    } else {
      makeApiCall(
        studentService.getAssignmentMissedWords,
        assignment.assignment_submission,
      )
        .then((respWords) => {
          setMissedWords(respWords);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setIsLoading(false);
        });
    }
  }, [assignment, missedWords, isLoading, makeApiCall]);

  const handleExitAssignment = () => {
    setAssignment(null);
    navigate(-1);
  };

  const handleBookCompleted = () => {
    if (
      !assignment ||
      !book ||
      assignment.assignment_submission.completion_score === 100
    )
      return;
    const updatedAssignment = StudentGradebookEntry.fromServerEntry({
      assignment: assignment.assignment.toJSON(),
      assignment_submission: {
        ...assignment.assignment_submission.toJSON(),
        grade: 100,
        completion_score: 100,
        correctness_score: Math.max(
          (1 - missedWords.length / book.word_count) * 100,
          0,
        ),
        key_word_accuracy_score: calculateKeyWordAccuracy(
          missedWords,
          assignment.assignment.key_words,
          book.getWords(),
        ),
        date_completed: new Date(Date.now()).toJSON(),
        word_index: book.word_count,
        completion_time: elapsedTime,
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    makeApiCall(
      studentService.updateAssignmentSubmission,
      updatedAssignment.assignment_submission,
    ).catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const handleStopReading = (index: number) => {
    console.debug(`saving at index: ${index}`);
    if (
      !assignment ||
      !book ||
      index === assignment.assignment_submission.word_index
    )
      return;
    const updatedAssignment = StudentGradebookEntry.fromServerEntry({
      assignment: assignment.assignment.toJSON(),
      assignment_submission: {
        ...assignment.assignment_submission.toJSON(),
        grade: (index / book.word_count) * 100,
        completion_score: (index / book.word_count) * 100,
        correctness_score: Math.max((1 - missedWords.length / index) * 100, 0),
        key_word_accuracy_score: 0,
        word_index: index,
        completion_time: elapsedTime,
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    studentService
      .updateAssignmentSubmission(updatedAssignment.assignment_submission)
      .catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const handleMissedWord = async (
    word: string,
    index: number,
    known: boolean,
    question?: Question,
  ) => {
    if (
      !assignment ||
      missedWords.findIndex(
        (mw) => mw.word === word && mw.word_index === index,
      ) !== -1
    )
      return;

    studentService
      .createMissedWord(
        ReadingUtils.stripSpecialCharsFromEndsOfString(word),
        assignment.assignment_submission.getId(),
        index,
        known,
        assignment.assignment.key_words.includes(word),
        question?.id,
      )
      .then((respWord) =>
        setMissedWords((prevWords) => [...prevWords, respWord]),
      )
      .catch((error) => alert(error.message));
  };

  const handleCloseQuestions = () => {
    setViewQuestions(false);
  };

  const handleViewQuestions = () => {
    setViewQuestions(true);
  };

  const handleQuestionResponseChanged = (
    question: Question,
    response: QuestionResponse,
  ) => {
    if (
      !questions ||
      ((response.response === '' || !response.response) &&
        (!response.choice || response.choice === '') &&
        question.question_type !== QuestionType.READALOUD)
    )
      return;

    const updatedQuestion = new StudentQuestion(
      Question.fromQuestion(question),
      QuestionResponse.fromQuestionResponse(response),
    );

    // Make the API call to save the updated response
    makeApiCall(studentService.updateQuestionResponse, updatedQuestion.response)
      .then((resp) => {
        // Update the questions array immutably, preserving order
        setQuestions((prevQuestions) =>
          prevQuestions!.map((q) =>
            q.question.getId() === question.getId() ? updatedQuestion : q,
          ),
        );
      })
      .catch((error) => alert(error.message));

    // Return the updated question object
    return updatedQuestion;
  };

  const handleListeningPaused = (timeStamp: number, duration: number) => {
    console.debug('paused', timeStamp, duration);
    if (
      !assignment ||
      !book ||
      timeStamp === assignment.assignment_submission.time_stamp
    )
      return;
    const completionScore = (timeStamp / duration) * 100;
    const updatedAssignment = StudentGradebookEntry.fromServerEntry({
      assignment: { ...assignment.assignment },
      assignment_submission: {
        ...assignment.assignment_submission,
        grade: completionScore,
        completion_score: completionScore,
        correctness_score: 100,
        key_word_accuracy_score: 100,
        time_stamp: timeStamp,
        date_completed:
          completionScore === 100 ? new Date(Date.now()).toJSON() : undefined,
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    studentService
      .updateAssignmentSubmission(updatedAssignment.assignment_submission)
      .catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const handleListeningCompleted = () => {
    console.debug('listening completed!');
    if (
      !assignment ||
      100 === assignment.assignment_submission.completion_score
    )
      return;
    const updatedAssignment = StudentGradebookEntry.fromServerEntry({
      assignment: { ...assignment.assignment },
      assignment_submission: {
        ...assignment.assignment_submission,
        grade: 100,
        completion_score: 100,
        correctness_score: 100,
        key_word_accuracy_score: 100,
        date_completed: new Date(Date.now()).toJSON(),
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    studentService
      .updateAssignmentSubmission(updatedAssignment.assignment_submission)
      .catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const handleMultiPartCompleted = () => {
    console.debug('multi part completed!');
    if (
      !assignment ||
      100 === assignment.assignment_submission.completion_score
    )
      return;
    const updatedAssignment = StudentGradebookEntry.fromServerEntry({
      assignment: { ...assignment.assignment },
      assignment_submission: {
        ...assignment.assignment_submission,
        grade: 100,
        completion_score: 100,
        correctness_score: questions
          ? questions.reduce((sum, item) => sum + item.response.grade, 0) /
            questions.length
          : 100,
        key_word_accuracy_score: 100,
        date_completed: new Date(Date.now()).toJSON(),
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    studentService
      .updateAssignmentSubmission(updatedAssignment.assignment_submission)
      .catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const updateCachedGradebook = async (entry: StudentGradebookEntry) => {
    // get the cached gradebook
    const cachedGradebook = await CachingEngine.getData(
      `${classroom?.getId()}-gradebook`,
    );
    if (!cachedGradebook) return;
    const gradebook =
      StudentGradebook.fromServerStudentGradebook(cachedGradebook).updateEntry(
        entry,
      );
    await CachingEngine.setData(`${classroom?.getId()}-gradebook`, gradebook);
  };

  if (!assignment) return <AppPage />;

  return (
    <AppPage>
      <div className="app-page-content">
        {!isReading && (
          <AppSidebar
            onExit={handleExitAssignment}
            onViewSettings={() => setViewSettings(true)}
          >
            <>
              <div className="sidebar-content-data">
                <div className="row">
                  <img src={calendar} alt="" />
                  <label className="label-small">
                    {pageContent.sidebar.due.label}
                  </label>
                  <AudioAssistanceButton text="Due Date" />
                </div>
                <label className="label-large">
                  {assignment.assignment.due_date
                    ? formatDate(new Date(assignment.assignment.due_date))
                    : 'No due date'}
                </label>
              </div>
              {assignment.assignment.start_date && (
                <div className="sidebar-content-data">
                  <label className="label-small">
                    {pageContent.sidebar.available.label}{' '}
                    {formatDate(new Date(assignment.assignment.start_date))}
                  </label>
                </div>
              )}
              <LanguageSelector />
              <div className="row spaced">
                <div className="row">
                  <img src={graduation} alt="" />
                  <label className="label-small">Learned Words</label>
                  <AudioAssistanceButton text="Learned words" />
                </div>
              </div>
              <MissedWordList missedWords={missedWords} />
              <div className="row spaced">
                <div className="row">
                  <img src={eye} alt="" />
                  <label className="label-small">Change to dyslexic font</label>
                  <AudioAssistanceButton text="Change to dyslexic font" />
                </div>
                <input
                  type="checkbox"
                  checked={useDyslexic}
                  onChange={() => setUseDyslexic((prev) => !prev)}
                />
              </div>
            </>
          </AppSidebar>
        )}
        <AssignmentContent
          assignment={assignment}
          book={book}
          missedWords={missedWords}
          questions={questions}
          isReading={isReading}
          useDyslexic={useDyslexic}
          setIsReading={setIsReading}
          onBookCompleted={handleBookCompleted}
          onStopReading={handleStopReading}
          onMissedWord={handleMissedWord}
          onViewQuestions={handleViewQuestions}
          onListeningCompleted={handleListeningCompleted}
          onListeningPaused={handleListeningPaused}
          onMultiPartCompleted={handleMultiPartCompleted}
          onQuestionResponseChanged={handleQuestionResponseChanged}
        />
        {questions && (
          <Modal isOpen={viewQuestions} onClose={handleCloseQuestions}>
            <QuestionsViewer
              state={
                !isOverdue
                  ? QuestionViewerState.InProgress
                  : assignment?.assignment_submission.questions_grade
                  ? QuestionViewerState.Graded
                  : QuestionViewerState.Locked
              }
              grade={assignment?.assignment_submission.questions_grade}
              questions={questions}
              onQuestionChange={handleQuestionResponseChanged}
              onExit={handleCloseQuestions}
            />
          </Modal>
        )}
        <Modal isOpen={viewSettings} onClose={() => setViewSettings(false)}>
          <AssignmentSettings />
        </Modal>
      </div>
    </AppPage>
  );
};

import { nanoid } from 'nanoid';
import Module from './Module';

export default class Classroom {
  public readonly id;
  public name: string;
  public teacher: string;
  public min_reading_level: number;
  public max_reading_level: number;
  public access_code: string;
  public language: string;
  public modules: Module[];
  public district?: string;
  public google_id?: string;
  public clever_id?: string;

  constructor(
    id: string,
    name: string,
    teacher: string,
    min_reading_level: number,
    max_reading_level: number,
    access_code: string,
    language: string,
    modules: Module[],
    district?: string,
    google_id?: string,
    clever_id?: string,
  ) {
    this.id = id;
    this.name = name;
    this.teacher = teacher;
    this.min_reading_level = min_reading_level;
    this.max_reading_level = max_reading_level;
    this.access_code = access_code;
    this.language = language;
    this.modules = modules;
    this.district = district;
    this.google_id = google_id;
    this.clever_id = clever_id;
  }

  static fromClassroom(classroom: Classroom) {
    return new Classroom(
      classroom.id,
      classroom.name,
      classroom.teacher,
      classroom.min_reading_level,
      classroom.max_reading_level,
      classroom.access_code,
      classroom.language,
      classroom.modules,
      classroom.district,
      classroom.google_id,
      classroom.clever_id,
    );
  }

  static fromServerClassroom(serverClassroom: Record<string, any>): Classroom {
    return new Classroom(
      serverClassroom['id'],
      serverClassroom['name'],
      serverClassroom['teacher'],
      serverClassroom['min_reading_level'],
      serverClassroom['max_reading_level'],
      serverClassroom['access_code'],
      serverClassroom['language'],
      serverClassroom['modules'],
      serverClassroom['district'],
      serverClassroom['google_id'],
      serverClassroom['clever_id'],
    );
  }

  static generateDefault(userId: string, districtId?: string): Classroom {
    return new Classroom(
      '',
      '',
      userId,
      1,
      1,
      nanoid().slice(0, 8),
      'en-US',
      [],
      districtId,
    );
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}

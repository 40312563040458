import React, { useState } from 'react';
import Book from 'models/Book';
import ActionButton from 'view/components/buttons/ActionButton';
import contentService from 'services/contentService';

import 'view/style/catalog/components/bookPreview.css';
import { WordList } from '../common/WordList';

const menu_book: string = require('assets/icons/menu_book_gray.svg').default;

interface BookPreviewProps {
  book: Book;
  liked: boolean;
  onLike: (book: Book, liked: boolean) => void;
  onView: (book: Book) => void;
  onSelect?: (book: Book) => void;
}

type BookDetail = {
  label: string;
  accessor: keyof Book;
};

const thumbs_up_regular: string =
  require('assets/icons/thumbs-up-regular.svg').default;
const thumbs_up_filled: string =
  require('assets/icons/thumbs-up-solid.svg').default;

export const BookPreview: React.FC<BookPreviewProps> = ({
  book,
  liked,
  onLike,
  onView,
  onSelect,
}) => {
  const formContent = contentService.getCatalogPage().book_preview;
  const [isLiked, setIsLiked] = useState<boolean>(liked);

  const handleLike = (book: Book) => {
    setIsLiked((prev) => {
      onLike(book, !prev);
      return !prev;
    });
  };

  return (
    <div className="book-preview">
      <div className="row">
        <label className="label-large">{book.title}</label>
        <img
          src={isLiked ? thumbs_up_filled : thumbs_up_regular}
          alt={isLiked ? 'liked' : 'not liked'}
          onClick={() => handleLike(book)}
        />
      </div>
      <div className="book-preview-content">
        <div className="book-card-cover">
          {book.cover_url ? (
            <img src={book.cover_url} alt="" />
          ) : (
            <img className="icon" src={menu_book} alt="" />
          )}
        </div>
        <div className="book-preview-details">
          {formContent.details.map((detail: BookDetail) => (
            <div
              key={`book-detail-${detail.label}`}
              className="sidebar-content-label"
              style={{ justifyContent: 'space-between' }}
            >
              <label className="label-normal">{detail.label}:</label>
              <label className="label-normal">
                {book[detail.accessor]?.toString()}
              </label>
            </div>
          ))}
          {book.tags.length > 0 && (
            <div key={`book-detail-tags`} className="sidebar-content-label">
              <div className="col">
                <label className="label-normal">Tags:</label>
                <WordList words={book.tags} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <ActionButton type="edit" onClick={() => onView(book)} label="View" />
        {onSelect && (
          <ActionButton
            type="go"
            onClick={() => onSelect(book)}
            label="Select"
          />
        )}
      </div>
    </div>
  );
};

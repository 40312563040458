export default class MissedWord {
  private readonly id;
  public word: string;
  public assignment_submission: string;
  public word_index: number;
  public known: boolean;
  public isKeyWord: boolean;
  public question?: string;

  constructor(
    id: string,
    word: string,
    assignment_submission: string,
    word_index: number,
    known: boolean,
    isKeyWord: boolean,
    question?: string,
  ) {
    this.id = id;
    this.word = word;
    this.assignment_submission = assignment_submission;
    this.word_index = word_index;
    this.known = known;
    this.isKeyWord = isKeyWord;
    this.question = question;
  }

  static fromMissedWord(missedWord: MissedWord) {
    return new MissedWord(
      missedWord.getId(),
      missedWord.word,
      missedWord.assignment_submission,
      missedWord.word_index,
      missedWord.known,
      missedWord.isKeyWord,
      missedWord.question,
    );
  }

  static fromServerMissedWord(missedWord: Record<string, any>): MissedWord {
    return new MissedWord(
      missedWord['id'],
      missedWord['word'],
      missedWord['assignment_submission'],
      missedWord['word_index'],
      missedWord['known'],
      missedWord['isKeyWord'],
      missedWord['question'],
    );
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      word: this.word,
      user_assignment: this.assignment_submission,
      word_index: this.word_index,
      known: this.known,
      isKeyWord: this.isKeyWord,
      question: this.question,
    };
  }
}

import React from 'react';
import AssignmentQuestion from '../questions/AssignmentQuestion';
import Question from 'models/Question';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import { QuestionType } from 'utils/types';
import AddImageButton from 'view/components/buttons/AddImageButton';

interface MultiPartQuestionFrameProps {
  question: Question;
  onUpdateQuestion: (
    updatedQuestion: Question,
    submit?: boolean,
    image?: File,
  ) => void;
  onDeleteQuestion: (question: Question) => void;
}

const MultiPartQuestionFrame: React.FC<MultiPartQuestionFrameProps> = ({
  question,
  onUpdateQuestion,
  onDeleteQuestion,
}) => {
  const handleAddQuestionChoice = () => {
    const updatedQuestion = Question.fromQuestion(question);

    updatedQuestion.choices = [
      ...(updatedQuestion.choices ?? []),
      new MultipleChoiceOption(
        updatedQuestion.choices?.length.toString() ?? '0',
        question.id,
        '',
        false,
      ),
    ];

    onUpdateQuestion(updatedQuestion);
  };

  const handleDeleteQuestionChoice = (question: any, choice: any) => {
    const updatedChoices = [
      ...(question.choices ?? []).filter(
        (c: MultipleChoiceOption) => Number(c.id) !== Number(choice.id),
      ),
    ];
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.choices = updatedChoices;
    updatedQuestion.question_type =
      !updatedChoices.length && question.question_type !== 'listening'
        ? 'text'
        : question.question_type;

    onUpdateQuestion(updatedQuestion);
  };

  const handleModifyQuestionChoice = (
    question: Question,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => {
    const updatedChoice = new MultipleChoiceOption(
      choice.id,
      choice.question,
      text,
      correct,
      choice.img,
      choice.img_file,
    );
    const updatedQuestion = Question.fromQuestion(question);
    if (
      question.question_type !== 'listening' &&
      question.question_type !== 'fillInBlank'
    ) {
      updatedQuestion.question_type = QuestionType.MULTIPLECHOICE;
    }

    const choiceIdx = question.choices?.findIndex(
      (c) => c.id === choice.id,
    ) as number;
    updatedQuestion.choices = [
      ...question.choices!.slice(0, choiceIdx),
      updatedChoice,
      ...question.choices!.slice(choiceIdx + 1),
    ];

    onUpdateQuestion(updatedQuestion, false);
  };

  const handleQuestionTextChange = (question: Question, text: string) => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.text = text;
    onUpdateQuestion(updatedQuestion, false);
  };

  const handleAddImage = (file?: File) => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.image_file = file;
    onUpdateQuestion(updatedQuestion, true, file);
  };

  const handleClearImage = () => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.image_file = undefined;
    updatedQuestion.image_url = undefined;
    onUpdateQuestion(updatedQuestion, true);
  };

  return (
    <>
      <AssignmentQuestion
        question={question}
        onDeleteQuestion={onDeleteQuestion}
        onQuestionTextChange={handleQuestionTextChange}
        onUpdateQuestion={onUpdateQuestion}
        onAddQuestionChoice={handleAddQuestionChoice}
        onDeleteQuestionChoice={handleDeleteQuestionChoice}
        onModifyQuestionChoice={handleModifyQuestionChoice}
      />
      <AddImageButton
        imageUrl={question.image_url}
        imageFile={question.image_file}
        onAddImage={handleAddImage}
        onClearImage={handleClearImage}
      />
    </>
  );
};

export default MultiPartQuestionFrame;

export default class AssignmentSubmission {
  public readonly id;
  public student_profile: string;
  public assignment: string;
  public grade: number;
  public completion_score: number;
  public correctness_score: number;
  public key_word_accuracy_score: number;
  public questions_grade: number;
  public date_completed: string;
  public word_index: number;
  public late_exemption_granted: boolean;
  public completion_time: number;
  public questions_answered: number;
  public time_stamp?: number;
  public google_id?: string;

  constructor(
    id: string,
    student_profile: string,
    assignment: string,
    grade: number,
    completion_score: number,
    correctness_score: number,
    key_word_accuracy_score: number,
    questions_grade: number,
    date_completed: string,
    word_index: number,
    late_exemption_granted: boolean,
    completion_time: number,
    questions_answered: number,
    time_stamp?: number,
    google_id?: string,
  ) {
    this.id = id.toString();
    this.student_profile = student_profile.toString();
    this.assignment = assignment.toString();
    this.grade = this.safeRound(grade, 2);
    this.completion_score = this.safeRound(completion_score, 2);
    this.correctness_score = this.safeRound(correctness_score, 2);
    this.key_word_accuracy_score = this.safeRound(key_word_accuracy_score, 2);
    this.questions_grade = this.safeRound(questions_grade, 2);
    this.date_completed = date_completed;
    this.word_index = word_index;
    this.late_exemption_granted = late_exemption_granted;
    this.completion_time = completion_time;
    this.questions_answered = questions_answered;
    this.time_stamp = time_stamp ? this.safeRound(time_stamp, 2) : undefined;
    this.google_id = google_id;
  }

  private safeRound(value: number, precision: number): number {
    const num = Number(value);
    return isNaN(num) ? 0 : Number(num.toFixed(precision));
  }

  static fromAssignmentSubmission(assignmentSubmission: AssignmentSubmission) {
    return new AssignmentSubmission(
      assignmentSubmission.id,
      assignmentSubmission.student_profile,
      assignmentSubmission.assignment,
      assignmentSubmission.grade,
      assignmentSubmission.completion_score,
      assignmentSubmission.correctness_score,
      assignmentSubmission.key_word_accuracy_score,
      assignmentSubmission.questions_grade,
      assignmentSubmission.date_completed,
      assignmentSubmission.word_index,
      assignmentSubmission.late_exemption_granted,
      assignmentSubmission.completion_time,
      assignmentSubmission.questions_answered,
      assignmentSubmission.time_stamp,
      assignmentSubmission.google_id,
    );
  }

  static fromServerAssignmentSubmission(
    assignmentSubmission: Record<string, any>,
  ): AssignmentSubmission {
    return new AssignmentSubmission(
      assignmentSubmission['id'],
      assignmentSubmission['student_profile'],
      assignmentSubmission['assignment'],
      assignmentSubmission['grade'],
      assignmentSubmission['completion_score'],
      assignmentSubmission['correctness_score'],
      assignmentSubmission['key_word_accuracy_score'],
      assignmentSubmission['questions_grade'],
      assignmentSubmission['date_completed'],
      assignmentSubmission['word_index'],
      assignmentSubmission['late_exemption_granted'],
      assignmentSubmission['completion_time'],
      assignmentSubmission['questions_answered'],
      assignmentSubmission['time_stamp'],
      assignmentSubmission['google_id'],
    );
  }

  static generateDefault(assignmentId: string) {
    return new AssignmentSubmission(
      '-1',
      '-1',
      assignmentId,
      0,
      0,
      0,
      0,
      0,
      '',
      -1,
      false,
      0,
      0,
    );
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      student_profile: this.student_profile,
      assignment: this.assignment,
      grade: this.grade,
      completion_score: this.completion_score,
      correctness_score: this.correctness_score,
      key_word_accuracy_score: this.key_word_accuracy_score,
      questions_grade: this.questions_grade,
      date_completed: this.date_completed,
      word_index: this.word_index,
      late_exemption_granted: this.late_exemption_granted,
      completion_time: this.completion_time,
      questions_answered: this.questions_answered,
      time_stamp: this.time_stamp,
      google_id: this.google_id,
    };
  }
}

import React, { useEffect, useState } from 'react';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useNavigate } from 'react-router-dom';
import teacherService from 'services/teacherService';
import TeacherGradebook from 'models/TeacherGradebook';
import { Filter, FilterOption } from 'view/components/common/Filter';
import { AppPage } from 'view/components/common/AppPage';
import SidebarButton from 'view/components/buttons/SidebarButton';
import Modal from 'view/components/common/Modal';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import ItemPanel from 'view/components/common/ItemPanel';
import AssignmentTypeForm from 'view/components/teacher/assignment/AssignmentTypeForm';
import Module from 'models/Module';
import { WordList } from 'view/components/common/WordList';
import ModuleForm from 'view/components/teacher/classroom/modules/ModuleForm';

import 'view/style/teacher/classroom.css';

const arrow_back: string = require('assets/icons/arrow_back_white.svg').default;
const add_circle: string = require('assets/icons/add_circle_white.svg').default;

const ClassroomModules: React.FC = () => {
  const { classroom } = useClassroomContext();
  const [selectedModule, setSelectedModule] = useState<Module>();
  const [gradebook, setGradebook] = useState<TeacherGradebook>();
  const [averagesDisplay, setAveragesDisplay] = useState<FilterOption[]>(
    contentService.getTeacherConstants().display_averages,
  );
  const [averageDisplay, setAverageDisplay] = useState<FilterOption>(
    contentService.getTeacherConstants().display_averages[0],
  );
  const [viewAddAssignment, setViewAddAssignment] = useState<boolean>(false);
  const [viewAddModule, setViewAddModule] = useState<boolean>(false);
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (gradebook || !classroom) return;
    const cachedGradebook = teacherService.getCachedGradebook();
    if (cachedGradebook) setGradebook(cachedGradebook);
    else {
      makeApiCall(teacherService.getGradebook, classroom.id)
        .then((respGradebook) => setGradebook(respGradebook))
        .catch((error) => alert(error.message));
    }
  }, [gradebook, classroom, makeApiCall]);

  const handleBack = () => {
    if (!classroom) return;
    if (selectedModule) {
      setSelectedModule(undefined);
      return;
    }
    navigate(`/teacher/classroom/${classroom.id}`);
  };

  const handleCreateAssignment = () => {
    setViewAddAssignment(true);
  };

  const handleEditAssignment = () => {
    if (!classroom || !selectedModule) return;
    navigate(`${selectedModule.id}/`);
  };

  const handleAverageDisplayChange = (
    checked: boolean,
    displayOption: FilterOption,
  ) => {
    setAveragesDisplay((prev) => {
      const updatedDisplayOptions = prev.map((option) =>
        option === displayOption
          ? { ...option, is_active: checked }
          : { ...option, is_active: false },
      );
      return updatedDisplayOptions;
    });
    if (checked) setAverageDisplay(displayOption);
  };

  const handleCreateModule = () => {
    setViewAddModule(true);
  };

  if (!gradebook) return <AppPage />;

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleBack}>
          <>
            <Filter
              type="onechoice"
              label="Averages"
              options={averagesDisplay}
              onOptionChange={handleAverageDisplayChange}
            />
            {selectedModule && gradebook && (
              <>
                <div className="assignment-detail-header">
                  <label className="label-medium">{selectedModule.name}</label>
                </div>
                <div className="assignment-details-list">
                  <div className="assignment-detail">
                    <label className="label-small">Key words</label>
                    <WordList words={selectedModule.key_words ?? []} />
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Completion</label>
                    <label className="label-small">
                      {
                        gradebook.averages.modules[selectedModule.id]
                          .completion_score
                      }
                      %
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Grade</label>
                    <label className="label-small">
                      {gradebook.averages.modules[selectedModule.id].grade}%
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Accuracy</label>
                    <label className="label-small">
                      {
                        gradebook.averages.modules[selectedModule.id]
                          .correctness_score
                      }
                      %
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Key Word Accuracy</label>
                    <label className="label-small">
                      {
                        gradebook.averages.modules[selectedModule.id]
                          .key_word_accuracy_score
                      }
                      %
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Completion Time</label>
                    <label className="label-small">
                      {
                        gradebook.averages.modules[selectedModule.id]
                          .completion_time
                      }
                      %
                    </label>
                  </div>
                </div>
              </>
            )}
            <SidebarButton
              type="go"
              onClick={handleCreateModule}
              icon={add_circle}
              label="New module"
            />
            <SidebarButton
              type="go"
              onClick={handleCreateAssignment}
              icon={add_circle}
              label="New Assignment"
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>{classroom?.name} Assignments</h1>
          <div className="item-list">
            {gradebook.modules &&
              gradebook.modules.map((module) => (
                <ItemPanel
                  key={`module-${module.id}`}
                  id={`module-${module.id}`}
                  selected={selectedModule === module}
                  onClick={() => setSelectedModule(module)}
                  onDoubleClick={handleEditAssignment}
                >
                  <div className="student-row-info">
                    <span className="label-normal">{module.name}</span>
                  </div>
                  {selectedModule === module ? (
                    <>
                      <span className="label-medium">View</span>
                      <img className="arrow-forward" src={arrow_back} alt="" />
                    </>
                  ) : (
                    averageDisplay &&
                    averageDisplay.alias &&
                    gradebook?.averages?.modules[module.id] && (
                      <span className="label-small">
                        {averageDisplay?.value} Average:{' '}
                        {
                          gradebook?.averages?.modules[module.id][
                            averageDisplay?.alias as
                              | 'grade'
                              | 'completion_score'
                              | 'correctness_score'
                              | 'key_word_accuracy_score'
                              | 'completion_time'
                          ]
                        }
                        %
                      </span>
                    )
                  )}
                </ItemPanel>
              ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={viewAddAssignment}
        onClose={() => setViewAddAssignment(false)}
      >
        <AssignmentTypeForm />
      </Modal>
      <Modal
        isOpen={viewAddModule}
        onClose={() => setViewAddModule(false)}
        style={{ width: '400px', height: 'fit-content' }}
      >
        <ModuleForm
          classroom={classroom}
          onExit={() => setViewAddModule(false)}
        />
      </Modal>
    </AppPage>
  );
};

export default ClassroomModules;

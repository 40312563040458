import useApiCall from 'contexts/ApiCall';
import Classroom from 'models/Classroom';
import Module from 'models/Module';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import teacherService from 'services/teacherService';
import ActionButton from 'view/components/buttons/ActionButton';
import FinePrint from 'view/components/common/FinePrint';
import WordAdder from 'view/components/common/WordAdder';

type ModuleFormProps = {
  module?: Module;
  classroom: Classroom | null;
  onExit: () => void;
};

type ModuleFormData = {
  name: string;
  index: number;
  classroom: string;
  start_date?: string;
  due_date?: string;
  reading_difficulty?: string;
  tags?: string[];
  key_words?: string[];
};

const initialFormData = {
  name: 'My Module',
  index: 0,
  classroom: '',
};

const ModuleForm: React.FC<ModuleFormProps> = ({
  module,
  classroom,
  onExit,
}) => {
  const [formData, setFormData] = useState<ModuleFormData>(
    module ? (module as ModuleFormData) : initialFormData,
  );
  const { assignmentId } = useParams();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!classroom) return;
    setFormData((prev: ModuleFormData) => {
      return {
        ...prev,
        classroom: classroom.id,
      };
    });
  }, [classroom]);

  const handleSubmitModule = () => {
    if (!module?.id || module.id === '') {
      makeApiCall(teacherService.createModule, formData)
        .then((resp) => {
          // setGradebook(gradebook?.addAssignment(resp));
          onExit();
        })
        .catch((err) => alert(err));
    } else {
      makeApiCall(teacherService.updateModule, assignmentId, formData)
        .then((resp) => {
          // setGradebook(gradebook?.updateAssignment(resp));
          onExit();
        })
        .catch((err) => alert(err));
    }
  };

  const handleDeleteModule = () => {
    if (!module?.id || module.id === '') return;
    makeApiCall(teacherService.deleteModule, module.id)
      .then((resp) => {
        // setGradebook(gradebook!.removeAssignment(assignment));
        onExit();
      })
      .catch((err) => alert(err));
  };

  const updateModule = (event: any) => {
    if (!formData || !classroom) return;
    var value = event.target.value;
    setFormData((prev: any) => {
      return {
        ...prev,
        [event.target.id]: value,
      };
    });
  };

  return (
    <div className="form">
      <label className="label-large">Module details</label>
      <div className="assignment-attribute-container">
        <label className="label-normal">Name</label>
        <input
          type="text"
          id="name"
          value={formData.name}
          onChange={updateModule}
        />
      </div>
      {/* <div className="assignment-attribute-container">
        <div className="row">
          <input
            id="start_date"
            type="datetime-local"
            value={formData.start_date}
            onChange={updateModule}
          />
          <input
            id="due_date"
            type="datetime-local"
            value={formData.due_date}
            onChange={updateModule}
          />
        </div>
      </div> */}
      <div className="assignment-attribute-container">
        <label className="label-normal">Key words</label>
        <WordAdder
          words={formData.key_words ?? []}
          onAddWord={(word: string) =>
            setFormData((prev) => {
              return { ...prev, key_words: [...(prev.key_words ?? []), word] };
            })
          }
          onDeleteWord={(word: string) =>
            setFormData((prev) => {
              return {
                ...prev,
                key_words: prev.key_words!.filter((w) => w !== word),
              };
            })
          }
        />
        <FinePrint text="Important words for this module" type="danger" />
      </div>
      <div className="row">
        {module?.id && module.id !== '' ? (
          <ActionButton
            type="delete"
            label="Delete"
            requiresConfirmation={true}
            confirmationMessage="Are you sure you want to delete this module? All associated assignments and submissions will be lost."
            onClick={handleDeleteModule}
          />
        ) : (
          <ActionButton type="delete" label="Cancel" onClick={onExit} />
        )}
        <ActionButton type="go" label="Submit" onClick={handleSubmitModule} />
      </div>
    </div>
  );
};

export default ModuleForm;

import React, { ReactNode } from 'react';

import 'view/style/common/components/itemPanel.css';

interface ItemPanelProps {
  id: string;
  selected?: boolean;
  expanded?: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
  children: ReactNode;
  highlight?: boolean;
  style?: React.CSSProperties;
}

const ItemPanel: React.FC<ItemPanelProps> = ({
  id,
  selected,
  expanded,
  onClick,
  onDoubleClick,
  children,
  highlight,
  style,
}) => {
  const handleClick = () => {
    if (selected) {
      onDoubleClick();
    } else {
      onClick();
    }
  };

  return (
    <div
      id={id}
      key={id}
      className={`item-panel${selected ? ' selected' : ''}${
        expanded ? ' expanded' : ''
      }${highlight ? ' highlighted' : ''}`}
      onClick={handleClick}
      style={style}
    >
      {children}
    </div>
  );
};

export default ItemPanel;

import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from 'services/userAuthService';
import googleApiService from 'services/googleAPIService';
import useApiCall from 'contexts/ApiCall';
import MultiPageForm from 'view/components/common/MultiPageForm';
import AccountTypeForm from 'view/components/auth/signup/AccountTypeForm';
import ProfileInformationForm from 'view/components/auth/signup/ProfileInformationForm';
import { FormValidationProvider } from 'contexts/FormValidationContext';
import StudentSubscriptionForm from 'view/components/auth/signup/StudentSubscriptionForm';
import { FormProvider } from 'contexts/FormContext';
import { SignupFormData } from 'utils/types';
import contentService from 'services/contentService';
import cleverService from 'services/cleverService';
import JoinDistrictForm from 'view/components/auth/signup/JoinDistrictForm';

const logo: string = require('assets/images/logos/official_logo.png');

const baseFormData: SignupFormData = {
  account_type: '',
  username: '',
  password: '',
  first_name: '',
  last_name: '',
  email: '',
  subscription: undefined,
  clever_id: undefined,
  subscription_code: undefined,
  district_clever_id: undefined,
};

export const SignupPage: React.FC = () => {
  const [userId, setUserId] = useState<string>();
  const [accountType, setAccountType] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [initialFormData, setInitialFormData] =
    useState<SignupFormData>(baseFormData);
  const signupContent = contentService.getSignupPage();
  const location = useLocation();
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!cleverService.getCleverId()) return;

    makeApiCall(cleverService.getUserInfo, cleverService.getCleverId())
      .then((resp) => {
        setInitialFormData({
          ...baseFormData,
          first_name: resp.data.name.first ?? '',
          last_name: resp.data.name.last ?? '',
          email: resp.data.email ?? '',
          clever_id: cleverService.getCleverId() ?? resp.data.id,
          district_clever_id: resp.data.district,
        });
      })
      .catch((error) => alert(error));
  }, [location, makeApiCall]);

  useEffect(() => {
    googleApiService.initializeGoogleAuth(googleSignupCallback);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [userId]);

  const googleSignupCallback = async (response: any) => {
    googleApiService.loginCallback(response);
  };

  const getPages = () => {
    let commonPages: ReactNode[] = [];

    if (!userId) {
      commonPages = [
        <AccountTypeForm key="account-type" setAccountType={setAccountType} />,
        <ProfileInformationForm key="profile-info" />,
      ];
    }

    if (accountType === 'Student') {
      return [
        ...commonPages,
        <StudentSubscriptionForm key="student-subscribe" />,
      ];
    } else if (accountType === 'Teacher') {
      if (initialFormData.district_clever_id) return commonPages;
      return [...commonPages, <JoinDistrictForm key="join-district" />];
    }

    return commonPages;
  };

  const getPageCallbacks = () => {
    let commonCallbacks: any = [];

    if (!userId) {
      commonCallbacks = [undefined, registerHandler];
    }

    if (accountType === 'Student') {
      return [...commonCallbacks, joinSubscriptionHandler];
    } else if (accountType === 'Teacher') {
      if (initialFormData.district_clever_id) return commonCallbacks;
      return [...commonCallbacks, joinSubscriptionHandler];
    }

    return commonCallbacks;
  };

  const joinSubscriptionHandler = (formData: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      makeApiCall(authService.joinSubscription, formData, userId)
        .then((resp) => {
          navigate(
            `/login?accessToken=${resp.token}&refreshToken=${resp.refresh}`,
          );
          // navigate('/login');
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

  const registerHandler = (formData: SignupFormData) => {
    return new Promise((resolve, reject) => {
      makeApiCall(authService.registerUser, formData)
        .then((respUser) => {
          setUserId(respUser.getId());
          if (formData.district_clever_id) {
            navigate(
              `/login?accessToken=${respUser.token}&refreshToken=${respUser.refresh}`,
            );
          }
          resolve({});
        })
        .catch((error) => reject(error));
    });
  };

  return (
    <div className="login-page">
      <div className="login">
        <div className="login-title">
          <img src={logo} alt="" />
          <h2>{signupContent.title}</h2>
        </div>
        <FormProvider<SignupFormData> initialFormData={initialFormData}>
          <FormValidationProvider>
            <MultiPageForm
              pages={getPages()}
              pageSubmitCallbacks={getPageCallbacks()}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onSubmit={() => {}}
              onExit={() => navigate('/')}
            />
          </FormValidationProvider>
        </FormProvider>
      </div>
    </div>
  );
};

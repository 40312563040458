import React, { useEffect, useState } from 'react';
import BarChart from './BarChart';
import { DisplayMissedWord } from 'utils/types';
import {
  ChartData,
  defaultMissedWordChartData,
} from 'view/pages/teacher/analytics/analytics';

interface ClassroomAnalyticsProps {
  missedWords: DisplayMissedWord[];
}

const ClassroomAnalytics: React.FC<ClassroomAnalyticsProps> = ({
  missedWords,
}) => {
  const [numDisplayWords, setNumDisplayWords] = useState<number>(10);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData[]>(
    defaultMissedWordChartData,
  );

  useEffect(() => {
    if (!missedWords) return;
    setChartLabels(
      missedWords.slice(0, numDisplayWords).map((dmw) => dmw.word),
    );
    setChartData((prevChartData) => [
      {
        ...prevChartData[0],
        data: missedWords.map((dmw) => dmw.missed),
      },
      {
        ...prevChartData[1],
        data: missedWords.map((dmw) => dmw.unknown),
      },
    ]);
  }, [numDisplayWords, missedWords]);

  const handleKeyWordToggle = (checked: boolean) => {
    if (!missedWords) return;
    // get the words we will be displaying
    const displayMissedKeyWords = missedWords
      .filter((dmw) => dmw.isKeyWord || !checked)
      .slice(0, numDisplayWords);
    setChartLabels(displayMissedKeyWords.map((mw) => mw.word));
    setChartData((prevChartData) => [
      {
        ...prevChartData[0],
        data: displayMissedKeyWords.map((dmw) => dmw.missed),
      },
      {
        ...prevChartData[1],
        data: displayMissedKeyWords.map((dmw) => dmw.unknown),
      },
    ]);
  };

  return (
    <>
      <div className="graph-container">
        <BarChart
          options={{}}
          data={{
            labels: chartLabels ?? [],
            datasets: chartData,
          }}
        />
        <div className="row">
          <div className="labeled-input label-left">
            <label># Words</label>
            <input
              type="number"
              value={numDisplayWords}
              onChange={(event) =>
                setNumDisplayWords(
                  Math.max(
                    Math.min(Number(event.target.value), missedWords.length),
                    1,
                  ),
                )
              }
            />
          </div>
          <label>Key words only</label>
          <input
            type="checkbox"
            onChange={(event) => handleKeyWordToggle(event.target.checked)}
          />
        </div>
      </div>
    </>
  );
};

export default ClassroomAnalytics;

import Assignment from './Assignment';
import AssignmentSubmission from './AssignmentSubmission';
import Module from './Module';
import TeacherGradebookEntry from './TeacherGradebookEntry';

export default class TeacherGradebook {
  public students: TeacherGradebookEntry[];
  public assignments: Assignment[];
  public averages: ClassAverages;
  public modules: Module[];

  constructor(
    students: TeacherGradebookEntry[],
    assignments: Assignment[],
    averages: ClassAverages,
    modules: Module[],
  ) {
    this.students = students;
    this.assignments = assignments;
    this.averages = averages;
    this.modules = modules;
  }

  static fromTeacherGradebook(teacherGradebook: TeacherGradebook) {
    return new TeacherGradebook(
      teacherGradebook.students.slice(),
      teacherGradebook.assignments.slice(),
      teacherGradebook.averages,
      teacherGradebook.modules,
    );
  }

  static fromServerGradebook(
    serverGradebook: Record<string, any>,
  ): TeacherGradebook {
    return new TeacherGradebook(
      serverGradebook.students
        .sort((s1: Record<string, any>, s2: Record<string, any>) => {
          // Compare last names
          if (s1.last_name < s2.last_name) return -1;
          if (s1.last_name > s2.last_name) return 1;

          // If last names are equal, compare first names
          if (s1.first_name < s2.first_name) return -1;
          if (s1.first_name > s2.first_name) return 1;

          // Names are equal
          return 0;
        })
        .map((student: Record<string, any>) =>
          TeacherGradebookEntry.fromServerEntry(
            student,
            serverGradebook['assignments'],
          ),
        ),
      serverGradebook['assignments']
        .map((asgn: Assignment) => Assignment.fromServerAssignment(asgn))
        .sort((a1: Assignment, a2: Assignment) => {
          const dueDate1 = a1.due_date ? new Date(a1.due_date).getTime() : null;
          const dueDate2 = a2.due_date ? new Date(a2.due_date).getTime() : null;

          if (dueDate1 === null && dueDate2 === null) return 0; // Both are null, considered equal
          if (dueDate1 === null) return 1; // a1's due_date is null, so it should come after
          if (dueDate2 === null) return -1; // a2's due_date is null, so it should come after

          // If both dates are non-null, compare by timestamp
          return dueDate2 - dueDate1;
        }),
      serverGradebook['averages'],
      serverGradebook['modules'],
    );
  }

  public updateAssignmentSubmission(
    updatedSubmission: AssignmentSubmission,
  ): TeacherGradebook {
    const studentEntry = this.students.find(
      (s) =>
        Number(s.student_profile.id) ===
        Number(updatedSubmission.student_profile),
    );
    if (!studentEntry) return this;

    const newStudentEntry = TeacherGradebookEntry.fromServerEntry(
      {
        ...studentEntry,
        assignment_submissions: [
          ...studentEntry.assignment_submissions.filter(
            (sa) => Number(sa.id) !== Number(updatedSubmission.id),
          ),
          updatedSubmission,
        ],
      },
      this.assignments,
    ) as TeacherGradebookEntry;

    return TeacherGradebook.fromServerGradebook({
      ...this,
      students: [
        ...this.students.filter(
          (s) =>
            Number(s.student_profile.id) !==
            Number(updatedSubmission.student_profile),
        ),
        newStudentEntry,
      ],
    });
  }

  public addAssignment(assignment: Assignment): TeacherGradebook {
    return TeacherGradebook.fromServerGradebook({
      ...this,
      assignments: [...this.assignments, assignment],
      averages: {
        ...this.averages,
        assignments: {
          ...this.averages.assignments,
          [assignment.id]: {
            grade: 0,
            completion_score: 0,
            correctness_score: 0,
            key_word_accuracy_score: 0,
            completion_time: 0,
          },
        },
      },
    });
  }

  public updateAssignment(assignment: Assignment): TeacherGradebook {
    return TeacherGradebook.fromServerGradebook({
      ...this,
      assignments: [
        ...this.assignments.filter(
          (asgn) => Number(asgn.id) !== Number(assignment.id),
        ),
        assignment,
      ],
    });
  }

  public removeAssignment(assignment: Assignment): TeacherGradebook {
    return TeacherGradebook.fromServerGradebook({
      ...this,
      assignments: this.assignments.filter(
        (asgn) => Number(asgn.id) !== Number(assignment.id),
      ),
    });
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}

interface ClassAverages {
  grade: number;
  completion_score: number;
  correctness_score: number;
  key_word_accuracy_score: number;
  completion_time: number;
  assignments: {
    [key: string]: {
      grade: number;
      completion_score: number;
      correctness_score: number;
      key_word_accuracy_score: number;
      completion_time: number;
    };
  };
  modules: {
    [key: string]: {
      grade: number;
      completion_score: number;
      correctness_score: number;
      key_word_accuracy_score: number;
      completion_time: number;
    };
  };
}

import React from 'react';
import MissedWordTable from './MissedWordTable';
import { DisplayMissedWord } from 'utils/types';

interface StudentAnalyticsProps {
  missedWords: DisplayMissedWord[];
}

const StudentAnalytics: React.FC<StudentAnalyticsProps> = ({ missedWords }) => {
  return (
    <>
      <div className="graph-container">
        {missedWords && <MissedWordTable missedWords={missedWords} />}
      </div>
    </>
  );
};

export default StudentAnalytics;

export default class StudentProfile {
  public readonly id;
  public user: string;
  public classroom: string;
  public reading_level: number;
  public speech_accuracy_level: string;

  constructor(
    id: string,
    user: string,
    classroom: string,
    reading_level: number,
    speech_accuracy_level: string,
  ) {
    this.id = id;
    this.user = user;
    this.classroom = classroom;
    this.reading_level = reading_level;
    this.speech_accuracy_level = speech_accuracy_level;
  }

  static fromStudentProfile(studentProfile: StudentProfile) {
    return new StudentProfile(
      studentProfile.id,
      studentProfile.user,
      studentProfile.classroom,
      studentProfile.reading_level,
      studentProfile.speech_accuracy_level,
    );
  }

  static fromServerStudentProfile(
    serverStudentProfile: Record<string, any>,
  ): StudentProfile {
    return new StudentProfile(
      serverStudentProfile['id'],
      serverStudentProfile['user'],
      serverStudentProfile['classroom'],
      serverStudentProfile['reading_level'],
      serverStudentProfile['speech_accuracy_level'],
    );
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}

import MissedWord from 'models/MissedWord';

export const calculateKeyWordAccuracy = (
  missedWords: MissedWord[],
  keyWords: string[],
  allWords: string[],
): number => {
  const keyWordCount = allWords.filter((word) =>
    keyWords.includes(word),
  ).length;
  if (keyWordCount === 0) return 100;
  const missedKeyWordCount = missedWords.filter((word) =>
    keyWords.includes(word.word),
  ).length;
  return (missedKeyWordCount / keyWordCount) * 100;
};

import { useClassroomContext } from 'contexts/ClassroomContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ItemButton from 'view/components/common/ItemButton';
import Modal from 'view/components/common/Modal';
import PracticeAssignmentForm from './forms/Practice';
import Classroom from 'models/Classroom';

import 'view/style/teacher/components/assignment/assignmentTypeForm.css';

const arrow_drop_down: string =
  require('assets/icons/arrow_drop_down.svg').default;
const arrow_right: string = require('assets/icons/arrow_right.svg').default;

interface AssignmentTypeFormProps {}

const assignmentTypes = [
  { displayName: 'Reading', serverName: '' },
  { displayName: 'Listening', serverName: 'Read-Aloud' },
  { displayName: 'Multi-part (beta)', serverName: 'Multi-Part' },
];

const assessmentTypes = [
  {
    displayName: 'Speaking assessment (work in progress)',
    serverName: 'Speaking',
  },
  { displayName: 'Listening assessment', serverName: 'Listening' },
];

const AssignmentTypeForm: React.FC<AssignmentTypeFormProps> = () => {
  const [showPracticeWindow, setShowPracticeWindow] = useState<boolean>(false);
  const { classroom } = useClassroomContext();
  const [viewTemplateOptions, setViewTemplateOptions] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const navToAssignmentForm = (assignmentType: string) => {
    navigate(
      `/teacher/classroom/${classroom?.getId()}/new-assignment/${assignmentType}`,
    );
  };

  return (
    <div className="form assignment-type">
      <label htmlFor="assignment-type-form" className="label-large">
        Choose assignment type
      </label>
      <div id="assignment-type-form" className="form-contents">
        {assignmentTypes.map((assignmentType) => (
          <ItemButton
            key={`asgn-type-${assignmentType.displayName}`}
            label={assignmentType.displayName}
            onClick={() => navToAssignmentForm(assignmentType.serverName)}
          />
        ))}
        <ItemButton
          key={`asgn-type-practice`}
          label="Practice"
          onClick={() => setShowPracticeWindow(true)}
        />
        <div
          className="row"
          onClick={() => setViewTemplateOptions((prev) => !prev)}
        >
          <label className="label-medium">Or try an assessment</label>
          <img
            src={viewTemplateOptions ? arrow_drop_down : arrow_right}
            alt="dropdown"
          />
        </div>
        {viewTemplateOptions &&
          assessmentTypes.map((assessmentType) => (
            <ItemButton
              key={`asgn-type-${assessmentType.displayName}`}
              label={assessmentType.displayName}
              onClick={() => navToAssignmentForm(assessmentType.serverName)}
            />
          ))}
      </div>
      <Modal
        isOpen={showPracticeWindow}
        onClose={() => setShowPracticeWindow(false)}
      >
        <PracticeAssignmentForm
          classroom={classroom as Classroom}
          onExit={() => setShowPracticeWindow(false)}
        />
      </Modal>
    </div>
  );
};

export default AssignmentTypeForm;

import { parseError } from 'utils/utils';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import apiService from './apiService';

export class CleverService {
  private static instance: CleverService;
  private cookies: Cookies;

  constructor() {
    this.cookies = new Cookies();
  }

  public static get(): CleverService {
    if (!CleverService.instance) {
      CleverService.instance = new CleverService();
    }

    return CleverService.instance;
  }

  getCleverId(): string {
    return this.cookies.get('cleverId');
  }

  getCleverToken(): string {
    return this.cookies.get('cleverToken');
  }

  isAuthenticated(): boolean {
    return this.cookies.get('cleverToken')!!;
  }

  saveToken(cleverToken: string) {
    this.cookies.set('cleverToken', cleverToken, { path: '/' });
  }

  getUserInfo = async (
    cleverId: string,
  ): Promise<Record<string, any> | null> => {
    try {
      const cleverToken = this.cookies.get('cleverToken');
      if (!cleverToken) return null;

      const response = await axios.get(
        `https://api.clever.com/v3.0/users/${cleverId}`,
        {
          headers: {
            Authorization: `Bearer ${cleverToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getToken = async (): Promise<Record<string, any> | null> => {
    try {
      const response = await apiService.api.get('/clever/token');
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getClassrooms = async (
    cleverId: string,
  ): Promise<Record<string, any> | null> => {
    try {
      const cleverToken = this.cookies.get('cleverToken');
      if (!cleverToken) return null;

      const response = await axios.get(
        `https://api.clever.com/v3.0/users/${cleverId}/sections`,
        {
          headers: {
            Authorization: `Bearer ${cleverToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getClassroomStudents = async (
    classroomCleverId: string,
  ): Promise<Record<string, any> | null> => {
    try {
      const cleverToken = this.cookies.get('cleverToken');
      if (!cleverToken) return null;

      const response = await axios.get(
        `https://api.clever.com/v3.0/sections/${classroomCleverId}/users?role=student`,
        {
          headers: {
            Authorization: `Bearer ${cleverToken}`,
          },
        },
      );
      return response.data.map((r: any) => {
        return {
          username: '',
          password: '',
          first_name: r.data.name.first,
          last_name: r.data.name.last,
          email: '',
          clever_id: '',
        };
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const cleverService = CleverService.get();
export default cleverService;

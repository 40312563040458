import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import ItemPanel from 'view/components/common/ItemPanel';
import Module from 'models/Module';
import academyService from 'services/academyService';
import Button from 'view/components/buttons/Button';
import { useUserContext } from 'contexts/UserContext';
import { useClassroomContext } from 'contexts/ClassroomContext';
import StudentProfile from 'models/StudentProfile';
import StudentGradebook from 'models/StudentGradebook';
import studentService from 'services/studentService';

const language: string = require('assets/icons/language.svg').default;
const wormy: string = require('assets/images/logos/wormy-apple.png');
const check = require('assets/icons/check-solid.svg').default;

const AcademyPage: React.FC = () => {
  const { user } = useUserContext();
  const { classroom, setClassroom } = useClassroomContext();
  const [selectedModule, setSelectedModule] = useState<Module>();
  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [modules, setModules] = useState<Module[]>();
  const [gradebook, setGradebook] = useState<StudentGradebook>();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || classroom) return;
    makeApiCall(academyService.getAcademy)
      .then((resp) => {
        setClassroom(resp.classroom);
        if (resp.student) {
          setStudentProfile(resp.student);
        }
      })
      .catch((error) => navigate(-1));
  }, [
    user,
    classroom,
    studentProfile,
    makeApiCall,
    navigate,
    setStudentProfile,
    setClassroom,
  ]);

  useEffect(() => {
    if (!classroom) return;
    makeApiCall(studentService.getStudentProfile, user, classroom).then(
      (resp) => setStudentProfile(resp),
    );
  }, [user, classroom, makeApiCall]);

  useEffect(() => {
    if (modules || gradebook || !studentProfile || !classroom) return;
    makeApiCall(studentService.getGradebook, studentProfile.id, classroom?.id)
      .then((respGradebook) => setGradebook(respGradebook))
      .catch((error) => alert(error.message));
    makeApiCall(academyService.getModules, classroom.id)
      .then((resp) => setModules(resp))
      .catch((error) => alert(error));
  }, [modules, gradebook, studentProfile, classroom, makeApiCall]);

  const handleJoinAcademy = () => {
    if (!user || !classroom) return;
    makeApiCall(academyService.joinAcademy, user.id, classroom.id)
      .then((resp) => {
        setClassroom(resp.classroom);
        setStudentProfile(resp.student_profile);
      })
      .catch((error) => alert(error));
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={() => navigate(-1)}>
          <div className="col" style={{ alignItems: 'center' }}>
            <img src={wormy} alt="wormy" style={{ width: '150px' }} />
            <span className="label-normal">
              Welcome to Orator Academy. This is a beta version of our
              self-paced English language learning track. Try out our modules or
              generate your own lessons by clicking "Language Practice" in the
              top right
            </span>
            {studentProfile ? (
              <div className="row">
                <label className="label-normal">Enrolled</label>
                <img src={check} alt="+" />
              </div>
            ) : (
              <Button
                label="Join for free!"
                text="Join for free!"
                type="go"
                onClick={handleJoinAcademy}
              />
            )}
          </div>
        </AppSidebar>
        <div className="app-main-content">
          <div className="row spaced" style={{ paddingBottom: '20px' }}>
            <h1>Orator Academy</h1>
            <div
              className="header-row-button"
              onClick={() => navigate('language-practice')}
            >
              <img src={language} alt="" />
              <span className="label-normal">Language Practice</span>
            </div>
          </div>
          <div className="item-list">
            {modules?.map((m, idx) => {
              const module = modules.find((m) => m.index === idx);
              if (!module) return null;
              return (
                <ItemPanel
                  key={`module-${idx}`}
                  id={`module-${idx}`}
                  onClick={() => setSelectedModule(module)}
                  onDoubleClick={() => navigate(`module/${selectedModule?.id}`)}
                  selected={selectedModule === module}
                >
                  <span className="label-normal">
                    Module {idx + 1}: {module.name}
                  </span>
                </ItemPanel>
              );
            })}
          </div>
        </div>
      </div>
    </AppPage>
  );
};

export default AcademyPage;
